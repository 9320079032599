@import '../../../../../styles/variables';
@import '../../../../../styles/mixins';

.fieldset {
  margin-top: 1rem;
  margin-bottom: 0.5rem;
  padding: 0;
  border: 0;
  color: $blue-darker;
  width: 100%;

  input,
  select {
    border: 1px solid $gray-light;
    font-family: $font;
    font-size: 14px;
    line-height: 20px;
    font-weight: $regular;
    border-radius: 4px;
  }

  .select {
    position: relative !important;
    margin-top: 0.5rem;

    :global {
      .ui.dropdown:not(.button) > .default.text,
      .ui.default.dropdown:not(.button) > .text {
        color: $blue-super-dark-grayish;
      }
    }
  }
}
