///////////////////////////////////////////////////////////
// Gallery                                   (Component) //
///////////////////////////////////////////////////////////
@import '../../../../styles/variables.scss';

// Image and Vídeo gallery
.gallery {
  position: relative;
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
  ul {
    position: relative;
    overflow: hidden;
    display: block;
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    list-style: none;
    background-color: rgba(241, 241, 245, 1);
    // Mobile
    @media (max-width: breakpoints(medium)) {
      height: 320px;
    }

    li {
      background-color: $blue;
      position: absolute;
      height: 100%;
      width: 100%;
      z-index: 1;
      visibility: hidden;
      opacity: 0;

      &.active {
        top: 0;
        right: 0;
        z-index: 99;
        visibility: visible;
        opacity: 1;
        transition: visibility 0s linear 0s, opacity 3s;
        background-color: rgba(241, 241, 245, 1);
      }

      &.inactive {
        visibility: hidden;
        opacity: 0;
        transition: visibility 0s linear 3s, opacity 3s;
        background-color: rgba(241, 241, 245, 1);
      }

      iframe,
      video {
        width: 100%;
        height: 100%;
        border: 0;
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
      }

      img {
        width: 100%;
        height: 100%;
        background-color: rgba(241, 241, 245, 1);
      }
    }
  }
}
