@import '../../../styles/mixins';
@import '../../../styles/variables';

.knight_awarded {
  position: absolute;
  height: 30px;
  width: 150px;
  top: -10px;
  right: 70px;
  background-color: $blue-darker;
  color: $white;
  font-weight: 100;
  font-size: 14px;
  border-radius: 4px;
  text-align: center;
  line-height: 30px;
  font-family: $font;
  font-weight: 300;
  letter-spacing: 0em;
}

.content {
  position: relative;
  margin: 0;
  padding: 0;
  width: 100%;
  display: flex;

  .name_bio {
    margin-left: 20px;
    .bio {
      position: relative;
      white-space: pre-wrap;
      cursor: pointer;
      color: $gray-dark;
      font-weight: 325;
      font-size: 14px;
      line-height: 27px;
    }

    .no_cursor {
      cursor: unset;
    }

    .name {
      margin-bottom: 18px;
      color: #171725;
      font-weight: 350;
      font-size: 18px;
      line-height: 22px;
    }
  }

  .img {
    position: relative;
    max-width: 40%;
    float: left;
    margin-right: 1rem;

    img {
      max-width: 120px;
      max-height: 120px;
      border-radius: 8px;
      object-fit: contain;

      svg {
        position: absolute;
        top: 32px;
        left: 90px;
        width: 45px;
      }
    }
  }
}

.other_info {
  div {
    padding: 5px 0 10px 0;
    .subtitle {
      color: $blue-secondary;
      font-size: 14px;
    }

    .info {
      color: #92929d;
      font-weight: 100;
      font-size: 14px;
      line-height: 20px;
    }
  }
}
.wrapper {
  display: flex;
  justify-content: space-between;
  .email_info {
    display: flex;
    align-items: center;
    text-decoration: underline;
    text-underline-offset: 2px;
    gap: 10px;
    padding: 5px 0 10px 0;
    .link {
      cursor: pointer;
    }
  }
}
