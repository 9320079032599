@import '../../../styles/variables.scss';

.tooltip {
  position: absolute;
  top: 27.5px;
  left: 36px;
  width: auto;
  height: auto;
  max-height: 32rem;
  max-width: 32rem;
  overflow: hidden auto;
  background-color: #d4e1e8;
  z-index: 1;
  border-radius: 4px;

  .header {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    .title {
      white-space: nowrap;
      margin-right: 12px;
      font-weight: 300;
    }
  }

  .data_container {
    padding: 0.5rem;
    font-size: 16px;
    line-height: 140%;
    font-weight: 400;
    color: $gray-dark;

    .data {
      margin-top: 0.5rem;
      font-size: 14px;
      line-height: 140%;
      font-weight: 200;
      color: $gray-dark;
      display: flex;
      flex-wrap: wrap;
    }

    .label_container {
      width: min-content;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      background-color: $blue;
      border-radius: 4px;
      margin: 4px;
      padding: 4px 6px;

      .label {
        width: 100%;
        margin-right: 1rem;
        white-space: nowrap;
        color: #ffffff;
      }

      .removeIcon {
        cursor: pointer;
        color: #ffffff;
      }
    }
  }
}
