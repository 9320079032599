.body {
  &::-webkit-scrollbar {
    width: 14px;
  }

  &::-webkit-scrollbar-track {
    border-radius: 18px;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.11) inset;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #2360a0;
    border-radius: 18px;
  }
}
