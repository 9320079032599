@import '../../../../../styles/mixins';
@import '../../../../../styles/variables';

.standalone_banner {
  position: relative;
  width: 100%;
  border-radius: 4px;
  height: 350px;
  background: url('../../../../../public/images/partner-finder-banner.png');
  background-position: center left;
  background-repeat: no-repeat;
  background-size: cover;
  margin-bottom: 87px;

  &.bck_img {
    background: url('../../../../../public/images/mp-finder-banner.png')
      no-repeat;
    background-size: cover;
    background-position: center left;
  }

  .img_banner {
    width: 100%;
    height: 100%;
    z-index: -1;
  }

  .text_banner {
    position: absolute;
    z-index: 1;
    top: 2rem;
    left: 2rem;

    p {
      color: rgba(255, 255, 255, 1);
      font-size: 1.5rem;
      font-weight: 350;
      line-height: 145%;
      width: 80%;
      margin: 0;
    }
  }

  .img_partner_finder {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 1rem;
    position: absolute;
    z-index: 1;
    bottom: 2rem;
    left: 2rem;

    .titles_container {
      color: rgba(255, 255, 255, 1);
      h3,
      h5 {
        padding: 0;
        margin: 0;
      }

      h5 {
        font-size: 1rem;
        font-weight: 100;
        text-transform: uppercase;
      }
      h3 {
        font-size: 1.5rem;
        font-weight: 400;
      }
    }
  }
}

@media (min-width: 910px) {
  .standalone_banner {
    height: 410px;
    background-position: center center;

    .text_banner {
      top: 4.75rem;
      left: 4.75rem;

      p {
        font-size: 1.75rem;
        width: 50%;
        background-color: transparent;
      }
    }

    .img_partner_finder {
      bottom: 4.75rem;
      left: 4.75rem;

      .titles_container {
        h5 {
          font-size: 1rem;
        }
        h3 {
          font-size: 2rem;
        }
      }
    }
  }
}
