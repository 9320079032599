///////////////////////////////////////////////////////////
// InputText                                 (Component) //
///////////////////////////////////////////////////////////
@import '../../../styles/mixins.scss';

.container {
  position: relative;
  padding: 0;
  margin: 0.5rem 0 0 0;
  &.disabled {
    pointer-events: none;
    opacity: 0.5;
  }
  display: flex;
  flex-direction: column;
  gap: 0.25rem;

  .log {
    position: absolute;
    top: 8px;
    right: 8px;
    height: 70px;
    width: 100%;
    margin-top: 30px;
    min-width: 70%;
    max-width: 18rem;
    overflow-y: auto;
    background-color: #d4e1e8;
    z-index: 999;
    border-radius: 4px;
  }

  .image {
    position: absolute;
    top: 18px;
    right: 80px;
  }
}

.error {
  position: absolute;
  margin: 6px 0;
  color: $red;
}
