///////////////////////////////////////////////////////////
// SelectLimitedPV                                  (Component) //
///////////////////////////////////////////////////////////
@import '../../../styles/mixins';

.container {
  position: relative;
  padding: 0;
  margin: 0;
  display: grid;
  grid-template-columns: minmax(0, 1fr);
  grid-template-rows: auto 1fr;

  &.full_width {
    grid-column: span 2;
    @media (max-width: breakpoints(large)) {
      grid-column: 1;
    }
  }

  &.full_height {
    grid-row: span 2;
    height: 100%;
    @media (max-width: breakpoints(large)) {
      grid-row: 1;
    }
  }

  .labels_and_moderation {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    .label {
      font-size: 14px;

      .hasNote {
        padding-left: 5px;
        color: $gray;
        font-size: 14px;
      }
    }
  }
}
