@import '../../../styles/mixins';
@import '../../../styles/variables';

.wrapper {
  position: relative;
  height: 100%;
  width: 100%;

  .trigger {
    height: 100%;
    width: 100%;
  }

  .popup {
    position: absolute;
    top: 100%;
    right: 0;
    margin-top: 0.5rem;
    height: auto;
    width: auto;
    background-color: #f1f1f5;
    border-radius: 8px;
    margin-right: -157px;
  }
}
