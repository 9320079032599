@import '/styles/variables';

.line {
  width: 102%;
  border: 1px solid #f2f3f5;
  height: 0.01px;
  margin: 20px 0;
  margin-left: -20px;
}

.event_info {
  margin-left: 20px;

  h1 {
    font-family: $font;
    font-style: normal;
    font-weight: 350;
    font-size: 16px;
    line-height: 28px;
    color: #1f384c;
  }

  span {
    font-family: $font;
    font-style: normal;
    font-weight: 325;
    font-size: 14px;
    line-height: 17px;
    color: #53565a;
  }

  a {
    font-size: 14px;
  }

  .desc {
    white-space: pre-line;
  }

  .products {
    text-decoration: underline;
  }

  .dates {
    display: grid;
    grid-template-columns: auto 2px auto;
    gap: 1rem;
    place-items: center;

    .center_line {
      width: 2px;
      height: calc(100% + 40px);
      background-color: #f2f3f5;
    }
  }
}
