@import '../../../../../styles/variables';
@import '../../../../../styles/mixins';

.fieldset {
  margin-bottom: 0.5rem;
  margin-top: 1rem;
  padding: 0;
  border: 0;
  color: $blue-darker;
  width: 100%;
  font-weight: $regular;

  h5 {
    padding: 0;
    margin: 0;
    margin-top: 1rem;
    font-size: 16px;
    line-height: 25px;
    font-weight: $regular;
  }

  .select {
    position: relative !important;
    margin-top: 0.5rem;
    white-space: nowrap;

    :global {
      .ui.dropdown:not(.button) > .default.text,
      .ui.default.dropdown:not(.button) > .text {
        color: $blue-super-dark-grayish;
      }
    }
  }
}
