.dialog_footer_buttons {
  button {
    flex: 1;
    height: 100%;
    padding: 0 4rem;
    font-size: 1.125rem;
    line-height: 1.75rem;
    font-weight: 400;

    &:first-of-type {
      padding-left: 0;
      padding-right: 0;
    }
  }
}
