///////////////////////////////////////////////////////////
// InputText                                 (Component) //
///////////////////////////////////////////////////////////
@import '../../../styles/mixins.scss';

.requireReview {
  background-color: $input-was-changed-bg;
  border: 2px solid $input-was-changed-border;
  padding-right: 3rem;
}
.approved {
  border: 2px solid $input-approved-border;
  border-radius: 4px;
  &:focus {
    border: 2px solid $input-approved-border;
    box-shadow: unset;
  }
}
.dismiss {
  border: 2px solid $input-dismissed-border;
  border-radius: 4px;
  &:focus {
    border: 2px solid $input-dismissed-border;
    box-shadow: unset;
  }
}
.changed {
  border: 2px solid $input-changed-border;
  border-radius: 4px;
  &:focus {
    border: 2px solid $input-changed-border;
    box-shadow: unset;
  }
}
.container {
  position: relative;
  padding: 0;
  margin: 0;
  margin-top: 8px;
}
.image {
  position: absolute;
  top: 27px;
  right: 0.5rem;
}
.prevValue {
  margin-top: 5px;
  font-size: 12px;
  font-weight: 200;
}

.log {
  flex-shrink: 1;
  position: absolute;
  top: 8px;
  right: 8px;
  height: 70px;
  width: 100%;
  min-width: 70%;
  max-width: 18rem;
  overflow-y: auto;
  background-color: #d4e1e8;
  z-index: 999;
  border-radius: 4px;
}
