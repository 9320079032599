@import '../../../../styles/variables';
@import '../../../../styles/mixins';

.above_header {
  width: 100%;
  z-index: 900;
  position: relative;

  &:hover {
    z-index: 999;
  }

  &.center {
    transform: translateY(-50%);
  }

  @media (min-width: breakpoints(medium)) {
    &.center {
      transform: translateY(-50%);
    }
  }
}

.filters_container {
  display: block;
  width: 100%;

  .menu {
    margin: 0;
    padding: 0;
    border: 0;
    color: $gray-dark;
    gap: 1rem;
    width: 100%;
    height: 100%;

    .overlay {
      width: 100%;
      height: 50px;
      padding: 0;
      margin: 0;
      display: relative;

      .button_wrapper {
        position: absolute;
        top: 15%;
        right: 15px;
        display: flex;
        justify-content: space-between;
        width: 270px;

        .search_btn {
          font-size: 16px;
          width: 130px;
          padding: 9px;
          letter-spacing: 0.0900852px;
          font-weight: 300;
          margin-left: 10px;

          &.mobile {
            display: none;
          }
          &.desktop {
            display: block;
          }
        }
      }

      [data-tip] {
        position: relative;
      }

      [data-tip]:before {
        content: '';
        display: none;
        content: '';
        border-left: 5px solid transparent;
        border-right: 5px solid transparent;
        border-bottom: 8px solid $blue-darker;
        position: absolute;
        left: 20px;
        bottom: -21px;
        z-index: 8;
        font-size: 0;
        line-height: 0;
        width: 0;
        height: 0;
      }
      [data-tip]:after {
        display: none;
        width: 520px;
        content: attr(data-tip);
        position: absolute;
        left: 0px;
        padding: 1.5rem;
        background-color: $blue-darker;
        font-family: var(--gotham-font), sans-serif;
        margin-top: 91px;
        font-weight: 300;
        color: #fff;
        z-index: 9;
        font-size: 14px;
        font-weight: 400;
        line-height: 150%;
        -webkit-border-radius: 3px;
        -moz-border-radius: 3px;
        border-radius: 8px;
      }

      [data-tip]:hover:before,
      [data-tip]:hover:after {
        display: none;
      }

      .hide_tooltip[data-tip]:after,
      .hide_tooltip[data-tip]:before {
        display: none;
      }
    }

    .search_wrapper {
      position: relative;
      display: flex;
      flex-direction: row;
      width: 100%;
      height: 100%;
      box-shadow: 0 4px 4px rgba(51, 51, 51, 0.08);
      background-color: white;
      border-radius: 0 0 7px 7px;

      &.standalone {
        border-radius: 7px;
      }
      i {
        display: flex;
        justify-content: center;
        align-items: center;
        padding-left: 25px !important;
        padding-right: 10px;
        width: unset !important;
        cursor: unset !important;

        &:before {
          cursor: pointer;
        }
      }

      .suggestions_wrapper {
        position: absolute;
        top: 45px;
        left: 0;
        width: 100%;
        max-height: 400px;
        display: flex;
        flex-direction: column;
        border-bottom-left-radius: 4px;
        border-bottom-right-radius: 4px;
        box-shadow: 0 4px 4px rgba(51, 51, 51, 0.08);
        background-color: $gray-lighter;
        z-index: 1000;
        overflow-y: auto;

        :last-child {
          border-bottom-left-radius: 4px;
          border-bottom-right-radius: 4px;
        }

        .item {
          color: $blue;
          background-color: $white;
          display: flex;
          align-items: center;
          padding: 0.5rem 1rem;
          cursor: pointer;
          z-index: 1000;
          font-size: 14px;
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;
        }

        .item:hover {
          background-color: $very-light-gray-f6;
        }
      }
    }

    .name_icon {
      color: $blue-darker;
      position: absolute;
      z-index: 9999;
      right: 300px !important;
      @media (max-width: 767px) {
        right: 80px !important;
      }

      &::before {
        left: unset !important;
      }
    }
  }

  @media (min-width: breakpoints(medium)) {
    height: 70px;

    .menu {
      margin: 0 0 22px 0;
      flex-direction: row;
      justify-content: space-between;
      gap: 1.5rem;

      .overlay {
        height: 100%;

        [data-tip]:hover:before,
        [data-tip]:hover:after {
          display: block;
        }

        .hide_tooltip[data-tip]:after,
        .hide_tooltip[data-tip]:before {
          display: none;
        }

        .search_btn {
          &.mobile {
            display: none;
            position: relative;
          }
          &.desktop {
            display: none;
          }
        }
      }

      .search_wrapper {
        .suggestions_wrapper {
          top: 60px;
        }
      }
    }
  }
}

@media (max-width: 767px) {
  .filters_container {
    .menu {
      .overlay {
        .button_wrapper {
          .search_btn {
            &.desktop {
              display: none;
            }
          }
        }
      }
    }
  }
}

.error {
  font-weight: 400;
  font-size: 14px;
  text-decoration: underline;
  color: $red;
  position: absolute;
  top: 47%;
  right: 23rem;
  transform: translateY(calc(-50% + 3px));
}
