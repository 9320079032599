///////////////////////////////////////////////////////////
// InputTextArea                             (Component) //
///////////////////////////////////////////////////////////
@import '../../../styles/mixins.scss';

.fieldset {
  width: 100%;
  &.full_width {
    grid-column: span 2;
    // Mobile
    @media (max-width: breakpoints(large)) {
      grid-column: 1;
    }
  }
  textarea {
    min-height: 120px;
  }
  .requireReview {
    color: $white;
    background-color: $red;
  }
  .red_req {
    color: red;
  }

  .disabled {
    cursor: not-allowed;
    color: $gray;
    background-color: $gray-lightest;
    font-size: 16px;
    padding: 1rem 2rem 1rem 1rem;
  }
}
