///////////////////////////////////////////////////////////
// InputText                                 (Component) //
///////////////////////////////////////////////////////////
@import '../../../styles/mixins.scss';

.fieldset {
  width: 100%;
  &.full_width {
    grid-column: span 2;
    // Mobile
    @media (max-width: breakpoints(large)) {
      grid-column: 1;
    }
  }

  .label {
    font-size: 16px;
    color: $blue-secondary;
    padding: 0;
    margin: 0;

    svg {
      cursor: pointer;
      &:first-of-type {
        padding: 0 5px;
      }
    }
  }
  .hasNote {
    padding-left: 5px;
    color: $gray;
    font-size: 14px;
  }
  .hasRequired {
    color: $red;
  }
  .labels_and_moderation {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
}

.container {
  position: relative;
  padding: 0;
  margin: 0;
  margin-top: 8px;
}

.requireReview {
  input {
    background-color: $input-was-changed-bg;
    border: 2px solid $input-was-changed-border;
    padding-right: 30px;
  }

}
.approved {
  input {
    border: 2px solid $input-approved-border;
    border-radius: 4px;
    &:focus {
      border: 2px solid $input-approved-border;
      box-shadow: unset;
    }
  }
}
.dismiss {
  input {
    border: 2px solid $input-dismissed-border;
    border-radius: 4px;
    &:focus {
      border: 2px solid $input-dismissed-border;
      box-shadow: unset;
    }
  }
}
.changed {
  input {
    border: 2px solid $input-changed-border;
    border-radius: 4px;
    &:focus {
      border: 2px solid $input-changed-border;
      box-shadow: unset;
    }
  }
}
