///////////////////////////////////////////////////////////
// Partner Card                              (Component) //
///////////////////////////////////////////////////////////
@import '../../../../styles/mixins';
@import '../../../../styles/variables';

.badges {
  display: flex;
  align-items: center;
  gap: 12px;

  .badge {
    z-index: 0;
    position: relative;

    &:not(:first-child) {
      margin-left: -25px;

      @media only screen and (max-width: 768px) {
        margin-left: 0;
      }
    }

    &:first-child {
      z-index: 4;
    }

    &:nth-child(2) {
      z-index: 3;
    }

    &:nth-child(3) {
      z-index: 2;
    }

    &:nth-child(4) {
      z-index: 1;
    }

    &:nth-child(5) {
      z-index: 0;
    }

    &:hover {
      z-index: 10;
      transform: scale(1.2);
    }
  }
}
