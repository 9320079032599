///////////////////////////////////////////////////////////
// SelectPV                                  (Component) //
///////////////////////////////////////////////////////////
@import '../../../styles/mixins';
@import '../../../styles/variables';

.full_width {
  grid-column: span 2;
  @media (max-width: breakpoints(large)) {
    grid-column: 1;
  }
}

.labels_and_moderation {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0.5rem;

  .label {
    font-size: 16px;
    color: $blue-secondary;
    padding: 0;
    margin: 0;
    display: flex;
    align-items: center;

    .red_req {
      font-size: 16px;
      color: red;
    }

    .hasNoteInform {
      font-family: $font;
      font-style: normal;
      font-weight: 325;
      font-size: 14px;
      line-height: 20px;
      color: #f0b51a;
      margin-left: 10px;
    }

    .hasNote {
      padding-left: 5px;
      color: $gray;
      font-size: 14px;
    }

    .hasRequired {
      color: $red;
    }

    svg {
      cursor: pointer;
      &:first-of-type {
        padding: 0 5px;
      }
    }
  }
}
