///////////////////////////////////////////////////////////
// Partner List                              (Component) //
///////////////////////////////////////////////////////////
@import '../../../styles/variables';
@import '../../../styles/mixins';

.events_list {
  padding: 0;
  margin: 0;
  list-style: none;
  display: grid;
  // 4k
  grid-template-columns: repeat(4, minmax(0, 1fr));
  gap: 2rem;

  @media (max-width: breakpoints('extra-extra-large')) {
    grid-template-columns: repeat(3, minmax(0, 1fr));
    grid-gap: 2rem;
  }

  @media (max-width: breakpoints('extra-large')) {
    grid-template-columns: repeat(2, minmax(0, 1fr));
    grid-gap: 1.5rem;
  }

  // Mobile
  @media (max-width: breakpoints(medium)) {
    grid-template-columns: repeat(1, minmax(0, 1fr));
    grid-gap: 2rem;
  }
}
