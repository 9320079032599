///////////////////////////////////////////////////////////
// Partner Card                              (Component) //
///////////////////////////////////////////////////////////
@import '../../../../../../../styles/variables';
@import '../../../../../../../styles/mixins';

.blog_posts {
  h3 {
    margin-top: 30px;
    margin-bottom: 50px;
  }

  .blog_post {
    box-sizing: border-box;
    position: relative;
    background-color: white;
    width: 99%;
    min-height: 153px;
    border-radius: 7px;
    overflow: hidden;
    margin: 0 auto;
    transition: all 0.1s ease-in;

    .content {
      position: absolute;
      top: 0px;
      width: 100%;
      height: 100%;
      z-index: 10;
      color: $black;
      box-sizing: border-box;
      padding: 2rem 1.5rem;
      z-index: 10;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: flex-start;
      transition: all 0.1s ease;

      @media (min-width: breakpoints(medium)) {
        padding: 2rem 1.75rem;
        padding-right: 5rem;
      }

      .title {
        max-width: 100%;
        font-size: 16px;
        font-weight: 400;

        span {
          font-weight: 300;
        }
      }

      .bottom_content {
        width: 100%;
        display: flex;
        justify-content: space-between;
        font-size: 0.9375rem;
        font-weight: 400;
        transition: all 0.1s ease;

        .minutes {
          display: flex;
          align-items: center;
          justify-content: center;
          line-height: 1px;
          gap: 0.4rem;
          stroke: #2360a0;
          color: #2360a0;
          transition: all 0.1s ease;

          .line {
            width: 2.5rem;
            height: 1px;
            background-color: $black;
            transition: all 0.1s ease;
            margin-right: 1rem;
          }
        }

        @media (min-width: 768px) and (max-width: 1199px) {
          justify-content: space-between;
          .minutes {
            .line {
              width: 20px;
              margin-right: 1.25rem;
            }
          }
        }
      }
    }

    .image_wrapper {
      position: absolute;
      top: 0;
      width: 100%;
      height: 100%;
      z-index: 5;
      opacity: 0;
      transition: all 0.1s ease;

      .wrapper {
        position: relative;
        height: 100%;
        width: 100%;

        .image {
          position: absolute;
          top: 0;
          height: 100%;
          width: 100%;
          object-fit: cover;
        }

        .overlay {
          position: absolute;
          height: 100%;
          width: 100%;
          background-color: #1f384c;
          opacity: 0.74;
        }
      }
    }

    &.hover_state {
      .content {
        color: white;

        .bottom_content {
          .minutes {
            color: white;
            stroke: white;

            .line {
              background-color: white;
            }
          }
        }
      }

      .image_wrapper {
        opacity: 1;
      }
    }
  }
}
