@import '../../../styles/variables.scss';

.button {
  padding: 9px 13px;
  font-family: $font;
  font-weight: $regular;
  font-size: 1rem;
  line-height: 1.25rem;
  text-align: center;
  text-transform: capitalize;
  letter-spacing: 1px;
  color: $white;
  background-color: $blue-darker;
  border: 0;
  border-radius: 4px;
  box-shadow: 0px 3.5px 14px 0px rgba(51, 51, 51, 0.08);
  display: flex;
  align-items: center;
  justify-content: center;

  .required {
    color: $red;
  }

  &.to_uppercase {
    text-transform: uppercase;
    font-size: 0.75rem;
  }

  .icon {
    svg {
      vertical-align: middle;
      margin-left: 8px;
    }
  }

  .icon_left_position {
    svg {
      vertical-align: middle;
      margin: 0;
      margin-right: 8px;
    }
  }

  .icon_left_position.no_title {
    svg {
      margin-right: 0;
    }
  }
  .icon_right_position {
    svg {
      vertical-align: middle;
      margin: 0;
      margin-left: 8px;
    }
  }

  .icon_right_position.no_title {
    svg {
      margin-left: 0;
    }
  }
  .icon_primary {
    svg {
      path {
        fill: $white;
      }
    }
  }
  .icon_secondary {
    svg {
      path {
        fill: #2360a0;
      }
    }
  }
  .icon_special {
    svg {
      path {
        fill: $white;
      }
    }
  }

  &:focus,
  &:hover {
    cursor: pointer;
    background-color: darken(#1f384c, 10%);
    outline: none;
  }
  &:active {
    transform: translateY(1px);
  }
  &:disabled {
    background-color: $disabled;
    pointer-events: none;
  }

  .icon_filters {
    margin-left: 1rem;

    &:hover {
      svg {
        rect {
          fill: #e42346;
        }
      }
    }
  }

  .icon_filters_chevron_left {
    margin-left: -2.5rem;
    margin-right: 0.5rem;
    vertical-align: middle;
    border: 1px solid #e3e3e5;
    border-radius: 20%;
    padding: 12px 10px 3px 10px;
  }

  &.with_icon {
    display: flex;
    align-items: center;

    .default_icon {
      margin-left: 8px;
      height: 12px;
      transform: translateY(-2px);
      svg {
        width: 14px;
        height: 14px;
        path {
          fill: $white;
        }
      }
    }
  }

  &.primary {
    background-color: $blue-darker;
    &:hover {
      background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)),
        $blue-darker;
    }
  }
  &.secondary {
    background-color: $white;
    color: #1f384c;
  }
  &.special {
    background-color: #1f384c;
    color: $white;
  }
  &.red {
    background-color: $red-darker;
    color: $white;
  }
  &.gray {
    background-color: $gray-light-inputs;
    color: $gray-dark;
  }
  &.unstyled {
    background-color: $white;
    color: #1f384c;
    box-shadow: 0px 3.47368px 13.8947px rgba(51, 51, 51, 0.08);
  }
  &.none {
    background-color: none !important;
    padding: 0;
    margin: 0;
    height: 0px;
  }
}
