///////////////////////////////////////////////////////////
// Filters popup content                     (Component) //
///////////////////////////////////////////////////////////
@import '../../../../../styles/variables';
@import '../../../../../styles/mixins';

// Filter popup content
.popupContent {
  width: 100%;
  max-width: 100%;
  z-index: 950;
  box-sizing: border-box;
  padding: 1rem 1rem 8rem 1rem;
  height: 100%;
  overflow: auto;

  :global(.grid.two_columns) {
    grid-gap: 0 1vw;
  }

  .info {
    font-size: 1rem;
    line-height: 24px;
    color: $black;
    font-weight: $regular;
    padding: 1rem 0;
  }

  header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;

    .title {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 0;
      transform: translateX(0);

      .backBtn {
        display: none;
        align-items: center;
        justify-content: center;
        border: 1px solid rgba(83, 86, 90, 0.15);
        border-radius: 5px;
        width: 34px;
        height: 34px;
        cursor: pointer;
      }

      h4 {
        font-size: 18px;
        font-weight: $medium;
        line-height: 22px;
        color: #232738;
        margin: 0;
        padding: 0;

        .selected {
          color: $blue-darker;
        }
      }
    }

    a {
      display: none;
      cursor: pointer;
      margin: 0;
      font-weight: 325;
      font-size: 14px;
      line-height: 25px;
      text-decoration-line: underline;
      color: #44444f;
    }

    .close {
      display: block;
      font-size: 1rem;
      font-weight: 300;
      font-style: normal;
      padding: 0.25rem;
      border-radius: 4px;
      border: 1px solid #e3e3e5;
    }
  }

  .products_column {
    border: none;
    padding: 0 1rem;
    border-bottom: 1px solid #e3e3e5;
  }

  .mobile_footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 16px;
    margin: 42px 0;

    a {
      cursor: pointer;
    }

    // Hides it above mobile
    @media (min-width: breakpoints(large)) {
      display: none;
    }
  }

  .column {
    padding-right: 0;
    border-right: none;
    padding-bottom: 1vw;
    border-bottom: 1px solid #e3e3e5;
  }

  @media (min-width: breakpoints(medium)) {
    padding: 2.5rem 3.125rem 5rem calc(3.125rem + 54px);
    width: 80vw;
    max-width: 90rem;
    overflow: visible;
    box-sizing: content-box;

    header {
      justify-content: space-between;

      .title {
        gap: 1.25rem;
        transform: translateX(-54px);

        .backBtn {
          display: flex;
        }

        h4 {
          margin: 0;
          padding: 0;
        }
      }

      a {
        display: block;
      }

      .close {
        display: none;
      }
    }

    .products_columns {
      grid-column: 1/3;
    }

    .column {
      padding-right: 1vw;
      border-right: 1px solid #e3e3e5;
      padding-bottom: 0;
      border-bottom: none;
    }
  }
}
