///////////////////////////////////////////////////////////
// Filters                                   (Component) //
///////////////////////////////////////////////////////////
@import '../../../../styles/variables';
@import '../../../../styles/mixins';

.button_filters {
  padding: 0;
  margin: 0;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #cc092f;
  border-radius: 5.34px;
  &:hover {
    background-color: linear-gradient(
        0deg,
        rgba(0, 0, 0, 0.2),
        rgba(0, 0, 0, 0.2)
      ),
      #cc092f;
    cursor: pointer;
  }

  &:focus {
    background-color: #cc092f;
  }

  .count {
    position: absolute;
    top: -7.5px;
    right: -7.5px;
    background-color: $blue;
    border: 2px solid $blue;
    color: $white;
    min-width: 20px;
    line-height: 22px;
    border-radius: 50%;
    text-align: center;
    font-size: 14px;
  }

  .label {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 12px;
    color: $white;
    font-weight: 300;
    font-style: normal;
    font-size: 16px;
    text-transform: capitalize;
    letter-spacing: 0.0900852px;
  }

  &.open {
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)),
      #cc092f;

    &:hover,
    &:focus {
      background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)),
        #cc092f;
    }
  }
}

.filters_on_desktop {
  display: none;
}

.filters_on_mobile {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 47px;
  right: 10px;
  top: -3px;
  padding: 0;
  margin: 0;
  height: 40px;
  background-color: #cc092f;
  border-radius: 5.34px;

  .button_filters {
    position: relative;

    .count {
      position: absolute;
      top: -100%;
      left: 100%;
      transform: translate(2px, -5px);
    }
  }
}

@media (min-width: breakpoints(medium)) {
  .button_filters {
    width: 130px;
    height: 48px;
  }

  .filters_on_desktop {
    display: block;
  }

  .filters_on_mobile {
    display: none;
  }
}
