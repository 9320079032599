///////////////////////////////////////////////////////////
// Rating Card                                (Component) //
///////////////////////////////////////////////////////////
@import '../../../../styles/variables';
@import '../../../../styles/mixins';

.status_card {
  flex: 1;
  padding: 2rem 1.5rem;
  border-radius: 8px;
  background-color: #fafafb;
  margin-right: 2rem;
  color: #1f384c;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 1rem;

  // Mobile
  @media (max-width: breakpoints(large)) {
    flex-direction: column;
    margin-right: 0;
    margin-bottom: 1rem;
  }
  &:last-child {
    margin-right: 0;
    margin-bottom: 0;
  }
  .header {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;

    .title {
      font-weight: $regular;
      font-size: 18px;
    }

    .responses {
      display: flex;
      align-items: center;

      span {
        margin-left: 10px;
      }
    }
  }

  .stars_comp {
    display: flex;
    margin-top: 15px;

    .stars {
      display: flex;
      span:not(:first-child) {
        margin-left: 1px;
      }
    }

    .stars_descr {
      padding: 2px;
      margin-left: 10px;
      font-weight: 325;
      font-size: 14px;
      color: #53565a;
    }

    .stars_descr_chg {
      padding: 4px 10px 4px 10px;
      margin-left: 10px;
      font-weight: 325;
      font-size: 14px;
      color: #53565a;
      background-color: rgba(240, 181, 26, 0.2);
    }
  }
  span {
    font-weight: $regular;
    font-size: 14px;
    line-height: 1.2rem;
    a {
      color: $red;
    }
  }
}

.input_responses {
  margin: 0;
  padding: 1px;
  width: 60px !important;
  height: 30px;
  font-family: $font;
  font-style: normal;
  font-weight: 325;
  font-size: 16px;
}

.input_responses_chg {
  margin: 0;
  padding: 1px;
  width: 60px !important;
  height: 30px;
  font-family: $font;
  font-style: normal;
  font-weight: 325;
  font-size: 16px;
  background-color: rgba(240, 181, 26, 0.2);
}

.stars_comp_view {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1.3rem;

  .stars_view {
    span {
      display: flex;
      gap: 0.5rem;
      margin-bottom: 1px;
    }
  }

  .responses_view {
    font-weight: 325;
    font-size: 14px;
    color: #53565a;
  }
}

.stars_comp_view_tile {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.8rem;

  .stars_view_tile {
    span {
      display: flex;
      gap: 0.375rem;
      margin-bottom: 1px;
    }
  }

  .stars_descr {
    font-weight: 325;
    font-size: 14px;
    line-height: 0;
    color: #53565a;
  }
}

.fieldset {
  margin-bottom: 0.5rem;
  padding: 0;
  border: 0;
  color: $blue-darker;
  width: 100%;

  .stars_comp {
    display: flex;
    gap: 0.75rem;
    margin-top: 15px;

    .stars {
      display: flex;
      span:not(:first-child) {
        margin-left: 1px;
      }
    }

    .values_wrapper {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .stars_descr {
        font-weight: 325;
        font-size: 14px;

        span {
          color: $blue-secondary;
        }
      }

      .close {
        cursor: pointer;
        height: 15px;
        transform: translateY(-2px);
      }
    }
  }
  span {
    font-weight: $regular;
    font-size: 14px;
    line-height: 1.2rem;
    a {
      color: $red;
    }
  }

  .checkbox {
    display: inline-flex;
    width: 100%;

    label {
      width: 100%;
    }
  }

  input,
  select {
    border: 1px solid $gray-light;
    font-family: $font;
    font-size: 14px;
    line-height: 20px;
    font-weight: $regular;
    border-radius: 4px;
  }

  .select {
    position: relative !important;
    margin-top: 0.5rem;

    :global {
      .ui.dropdown:not(.button) > .default.text,
      .ui.default.dropdown:not(.button) > .text {
        color: $blue-super-dark-grayish;
      }
    }
  }

  h5 {
    margin-top: 1rem;
    padding-bottom: 0;
    font-size: 16px;
    line-height: 25px;
    font-weight: $regular;
  }
}
