///////////////////////////////////////////////////////////
// Rating Card                                (Component) //
///////////////////////////////////////////////////////////
@import '../../../../../styles/variables';
@import '../../../../../styles/mixins';

.fieldset {
  margin-bottom: 0.5rem;
  padding: 0;
  border: 0;
  color: $blue-darker;
  width: 100%;

  .stars_comp {
    display: flex;
    gap: 0.75rem;
    margin-top: 15px;

    .stars {
      display: flex;
      span:not(:first-child) {
        margin-left: 1px;
      }
    }

    .values_wrapper {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .stars_descr {
        font-weight: 325;
        font-size: 14px;

        span {
          color: $blue-secondary;
        }
      }

      .close {
        cursor: pointer;
        height: 15px;
        transform: translateY(-2px);
      }
    }
  }
  span {
    font-weight: $regular;
    font-size: 14px;
    line-height: 1.2rem;
    a {
      color: $red;
    }
  }

  .checkbox {
    display: inline-flex;
    width: 100%;

    label {
      width: 100%;
    }
  }

  input,
  select {
    border: 1px solid $gray-light;
    font-family: $font;
    font-size: 14px;
    line-height: 20px;
    font-weight: $regular;
    border-radius: 4px;
  }

  .select {
    position: relative !important;
    margin-top: 0.5rem;

    :global {
      .ui.dropdown:not(.button) > .default.text,
      .ui.default.dropdown:not(.button) > .text {
        color: $blue-super-dark-grayish;
      }
    }
  }

  h5 {
    margin-top: 1rem;
    padding-bottom: 0;
    font-size: 16px;
    line-height: 25px;
    font-weight: $regular;
  }
}
