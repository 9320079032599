@import '../../../styles/variables.scss';

.loader {
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(151, 153, 155, 0.9);
  z-index: 10000;
  p {
    font-weight: bold;
    color: $white;
  }
}

.icon_container {
  height: 155px;
  width: 155px;
  background-image: url('../../../public/images/Loading-1.svg');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  transition: 0.1s;

  animation-name: img-load;
  animation-play-state: running;
  animation-timing-function: ease-in-out;
  animation-duration: 1s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;

  @keyframes img-load {
    0% {
      background-image: url('../../../public/images/Loading-1.svg');
    }
    12.5% {
      background-image: url('../../../public/images/Loading-2.svg');
    }
    25% {
      background-image: url('../../../public/images/Loading-3.svg');
    }
    37.5% {
      background-image: url('../../../public/images/Loading-4.svg');
    }
    50% {
      background-image: url('../../../public/images/Loading-5.svg');
    }
    62.5% {
      background-image: url('../../../public/images/Loading-6.svg');
    }
    75% {
      background-image: url('../../../public/images/Loading-7.svg');
    }
    87.5% {
      background-image: url('../../../public/images/Loading-8.svg');
    }
    100% {
      background-image: url('../../../public/images/Loading-9.svg');
    }
  }
}
