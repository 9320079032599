@import '../../../styles/variables';
@import '../../../styles/mixins';

.container {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;

  &.column {
    flex-direction: column;
  }

  button {
    border: none;
    box-shadow: none;
    background-color: none;
    color: none;
    margin: 0;
    padding: 0;

    cursor: pointer;
    font-family: $font;
    font-size: 0.875rem;
    font-weight: 300;
    line-height: 18px;
    padding: 4px 12px;
    border-radius: 3px;
    border-width: 1px;
    border-style: solid;
    color: $white;

    &.accept {
      background-color: $input-approved-border;
      border-color: $input-approved-border;

      &:disabled {
        color: #00000033;
        border-color: #00000033;
        cursor: default;
      }

      &.available {
        box-shadow: 0px 4px 4px rgba(146, 146, 157, 0.54);
      }
    }

    &.dismiss {
      background-color: $input-dismissed-border;
      border-color: $input-dismissed-border;

      &:disabled {
        color: #00000033;
        border-color: #00000033;
        cursor: default;
      }

      &.available {
        box-shadow: 0px 4px 4px rgba(146, 146, 157, 0.54);
      }
    }

    &.resumable {
      width: auto;
      max-width: 20px;
      padding: 4px 4px;
      overflow: hidden;
      transition: all 50ms ease-in;
      color: transparent;
      background-position: center;
      background-repeat: no-repeat;

      &.accept {
        background-image: url('../../../public/images/accept-icon-white.svg');

        &:disabled {
          color: transparent;
        }
      }

      &.dismiss {
        background-image: url('../../../public/images/decline-icon-white.svg');

        &:disabled {
          color: transparent;
        }
      }

      &:hover {
        padding: 4px 12px;
        max-width: 120px;
        color: $white;

        &.accept {
          background-image: none;
          &:disabled {
            color: #00000033;
          }
        }

        &.dismiss {
          background-image: none;
          &:disabled {
            color: #00000033;
          }
        }
      }
    }
  }
}
