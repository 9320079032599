@import '../../../styles/mixins';
@import '../../../styles/variables';

.header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: -30px;

  h3 {
    font-family: $font;
    font-style: normal;
    font-weight: 350;
    font-size: 22px;
    letter-spacing: 0.112433px;
    color: #1f384c;
    margin-bottom: 30px;
  }
  .arrows {
    display: flex;
    justify-content: center;
    align-items: center;

    svg {
      cursor: pointer;
      &:last-of-type {
        margin-left: 8px;
      }
    }

    .disabled {
      cursor: unset;
      path {
        fill: $disabled;
      }
    }
    .enabled {
      path {
        fill: $blue-darker;
      }
    }

    .disabled_vertical {
      transform: rotateZ(90deg);
      cursor: unset;
      path {
        fill: $disabled;
      }
    }

    .enabled_vertical {
      path {
        fill: $blue-darker;
      }
      transform: rotateZ(90deg);
    }
  }
}

.events {
  display: grid;
  grid-template-columns: 1fr;
  gap: 2rem;

  .event_card {
    width: 100%;
    height: 132px;
    background: #ffffff;
    flex: 0 0 33.33%;
    box-shadow: 0px 3.47368px 13.8947px rgba(51, 51, 51, 0.08);
    border-radius: 11.27px;
    display: flex;
    align-items: center;
    font-family: $font;
    margin-bottom: 50px;
    margin-right: 13px;
    cursor: pointer;

    .wrapper {
      display: flex;
      flex-direction: column;
      margin-left: 10px;
      margin-top: 5px;

      .images {
        position: relative;

        .event_image {
          width: 116px;
          height: 116px;
          object-fit: cover;
          border-radius: 11.27px;
        }

        .logo {
          position: absolute;
          object-fit: contain;
          left: 7px;
          bottom: 13px;
          height: 40px;
          background-color: #ffffff;
          border-radius: 4px;
        }
      }
      .info {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        margin-left: 15px;
        margin-top: 13px;
        margin-bottom: 13px;

        .title {
          font-weight: 350;
          font-size: 16px;
          line-height: 19px;
          color: #2360a0;
        }
        .type {
          font-weight: 350;
          font-size: 12px;
          line-height: 150%;
          color: #1f384c;
        }
        .date {
          display: flex;
          font-weight: 325;
          font-size: 12px;
          line-height: 20px;
          color: #1f384c;
        }
        .tagline {
          font-style: normal;
          font-size: 14px;
          line-height: 150%;
          color: #53565a;
        }
      }
    }
  }
}
