///////////////////////////////////////////////////////////
// Variables                                             //
///////////////////////////////////////////////////////////

// Colors /////////////////////////////////////////////////
$red-darker: #cc2130;
$red: #cc092f;
$red-very-soft: #ebafa6;
$red-soft: #eb5757;
$red-light-grayish: #f5dbd9;
$blue-darker: #2360a0;
$blue: #005c8a;
$blue-secondary: #1f384c;
$blue-light-grayish: #dfe2e9;
$blue-very-dark-grayish: #454d59;
$blue-super-dark-grayish: #1f384c;
$light-grayish-blue: #e5ecf4;
$black: #1f384c;
$ligher-black: #1d1c1d;
$darker-black: #263238;
$gray-darkest: #333333;
$gray-dark: #53565a;
$gray: #97999b;
$gray-light: #b5bfc9;
$gray-light-inputs: #e6e6e6;
$gray-lighter: #e2e3e4;
$gray-lightest: #eeeeee;
$stylish-blue: #3c71a6;
$green: #60b515;
$green-lime: #6fcf97;
$white: #ffffff;
$ligher-gray: #979797;
$yellow-soft: #f2c94c;
$maize: #ffcc47;
$dark-red: #cc2031;
$teal: #007b8c;
$disabled-gray: #6f6f6f;
$dark-grayish-blue: #a7a8aa;
$cadet-blue: #abb7c4;
$grayish-yellow: #d0d0ce;
$select-red: #ee4248;
$sonic-silver: #757575;
$light-white: #dbdbdb;
$lightest-white: #dfdfdf;
$very-light-gray: #f5f5f5;
$very-light-gray-f6: #f6f6f6;

//inputs colors - moderation changes
$input-was-changed-bg: #f0b51a33;
$input-was-changed-border: #f2c94c;
$input-approved-border: #6fcf97;
$input-dismissed-border: #eb5757;
$input-changed-border: #f2c94c;

// Semantic
$disabled: $gray;
$bg-sucess: #e5f6ec;
$bg-fail: #f6dcdc;
$bg-neutral: #f4f5f6;
$bg-attention: #fdefc3;
$bg-required: #f8f0da;

// Typography /////////////////////////////////////////////
// Font scaffold
$font: var(--gotham-font);
$font-size-input: 16px !important;
$font-size-select: 16px !important;
$font-size-label: 16px !important;
$font-size-tab-h3: 17px !important;
$font-size-button: 16px !important;

//Font Sizes
$font-size-pp-pitch-h3: 18px;
$font-size-pp-services-h3: 16px;
$font-size-ph-h1: 24px;
$font-size-pp-h3: 18px;
$font-size-pp-h4: 14px;
$font-size-pp-h6: 14px;
$font-size-pp-p: 14px;
$font-size-pp-li: 14px;
$font-size-bc-label: 16px;
$font-size-modal-title: 22px;
$font-size-modal-descr: 16px;

// Weights
$light: 100;
$regular: 200;
$medium: 400;
$bold: 600;

// Breakpoints
$breakpoints: (
  'small': 20rem,
  // 320px  – Phone
  'medium': 48rem,
  // 768px  – Tablet
  'large': 62rem,
  // 992px  – Tablet
  'extra-large': 75rem,
  // 1200px – Desktop
  'extra-extra-large': 2000px,
  // 1280px – Large Desktop
);
