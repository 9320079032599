///////////////////////////////////////////////////////////
// SelectPV                                  (Component) //
///////////////////////////////////////////////////////////
@import '../../../styles/mixins.scss';

.container {
  position: relative;
  padding: 0;

  &.disabled {
    pointer-events: none;
    opacity: 0.5;
  }

  .log {
    position: absolute;
    top: 8px;
    right: 8px;
    height: 70px;
    width: 100%;
    margin-top: 30px;
    min-width: 70%;
    max-width: 18rem;
    overflow-y: auto;
    background-color: #d4e1e8;
    z-index: 999;
    border-radius: 4px;
  }

  .actions_wrapper {
    position: absolute;
    right: 45px;
    top: 50%;
    transform: translateY(-50%);
    gap: 1rem;
    display: flex;
    flex-direction: row;
    align-items: center;

    .clear {
      svg {
        vertical-align: middle;
        font-size: 18px;
        color: rgb(204, 204, 204);
        path {
          fill: #CCCCCC;
        }
        &:hover {
          cursor: pointer;
          path {
            fill: $red;
          }
        }
      }
    }

    .image {
      padding-top: 5px;
      &:hover {
        cursor: pointer;
      }
    }
  }
}

.requireReview div {
  background-color: $bg-attention;
  height: fit-content;
  color: hsl(0, 0%, 20%);
}
.approved {
  border: 2px solid $green-lime;
  border-radius: 2px;
  &:focus {
    border: 2px solid $green-lime;
    box-shadow: unset;
  }
}
.dismiss {
  border: 2px solid $red-soft;
  border-radius: 2px;
  &:focus {
    border: 2px solid $red-soft;
    box-shadow: unset;
  }
}
.changed {
  border: 2px solid $yellow-soft;
  border-radius: 2px;
  &:focus {
    border: 2px solid $yellow-soft;
    box-shadow: unset;
  }
}

.hasColumns {
  [class*='-menu'] {
    div:first-of-type {
      display: grid;
      grid-template-columns: 1fr 1fr;
      text-align: -webkit-center;
      div {
        display: block;
        align-items: center;
        svg {
          margin-bottom: 1px;
          margin-left: 4px;
        }
      }
    }
  }
}
