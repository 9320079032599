///////////////////////////////////////////////////////////
// Pagination                                (Component) //
///////////////////////////////////////////////////////////
@import '../../../styles/variables.scss';
@import '../../../styles/mixins';

.pagination_with_data {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 50px;

  @media (max-width: breakpoints(large)) {
    flex-direction: column;
    gap: 1rem;
  }

  span {
    color: $gray-light;
    font-size: 14px;
    font-weight: $regular;
  }
}

.pagination_without_data {
  display: flex;
  width: fit-content;
  align-items: center;
  margin: 0 auto;
}

// Pagination
.pagination {
  display: flex;
  ol {
    align-self: center;
    list-style: none;
    margin: 0;
    padding: 0;
    li {
      display: inline-block;
      margin: 0 8px;
      background: #fafafb;
      color: #1f384c;
      border-radius: 5.37528px;
      padding: 5px 11px;

      &.active {
        background: $blue-darker;
        border-radius: 5.37528px;
        a {
          color: $white;
        }
      }
      a {
        display: block;
        font-weight: 400;
        font-style: normal;
        color: #1f384c;
      }

      &:hover {
        cursor: pointer;
      }

      &.dots {
        &:hover {
          cursor: default;
        }
      }
    }
  }
}

.button_pagination_nav {
  display: inline-block;
  margin-left: 12px;
  padding: 0;
  border: 0;
  background: none;
  .icon {
    width: unset;
    height: 31px;
  }

  &:hover {
    opacity: 0.7;
    cursor: pointer;
  }
  &:active {
    transform: translateY(1px);
  }
  &:focus {
    outline: none;
  }

  &.left {
    margin-right: 12px;
    margin-left: 0;
    transform: rotate(180deg);
  }

  &.disabled {
    path {
      fill: $disabled;
    }
    &:hover {
      cursor: not-allowed;
      opacity: 1;
    }
  }
  svg {
    vertical-align: middle;
  }
}
