///////////////////////////////////////////////////////////
// SelectLimitedMultiLog                     (Component) //
///////////////////////////////////////////////////////////
@import '../../../styles/mixins';

.container {
  position: relative;
  padding: 0;
  margin: 5px 0 0 0;
  &.disabled {
    pointer-events: none;
    opacity: 0.5;
  }
  &.full_height {
    height: 100%;
  }

  .image {
    position: absolute;
    bottom: 0.5rem;
    right: 0.5rem;

    &.bottom {
      right: 0.5rem;
      top: unset;
    }
    &.top {
      top: 1.25rem;
      right: 5rem;
      bottom: unset;
    }

    &.no_val {
      right: 3rem;
      top: 1.25rem;
      bottom: unset;
    }

    .log {
      position: absolute;
      top: 15px;
      right: 0px;
      height: 70px;
      width: 18rem;
      min-width: 70%;
      max-width: 18rem;
      overflow-y: auto;
      background-color: #d4e1e8;
      z-index: 999;
      border-radius: 4px;
    }
  }
}

.tooltip_wrapper {
  display: flex;

  .custom_tooltip {
    max-width: 30px;

    background-color: #e5ecf4;
    border-radius: 2px;
    width: auto;
    margin: 2px;
    font-weight: 400;
    font-size: 18px;
    padding: 5.5px 10px;
    color: #1f384c;
  }
  .tooltipChanged {
    background-color: #f2c94c;
    color: $white;
  }
}
