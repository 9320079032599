///////////////////////////////////////////////////////////
// Dashboard Engagement List                      (Page) //
///////////////////////////////////////////////////////////
@import '../../../../styles/mixins';
@import '../../../../styles/variables';

.modal_content {
  border-radius: 4px;
  padding: 0 !important;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  overflow-y: auto;
  max-height: 90vh;

  .header {
    display: flex;
    flex-direction: column;
    padding: 1rem;

    .header_grid {
      position: relative;
      color: #2360a0;
      font-size: 1.25rem;
      margin-bottom: 6px;
      line-height: 29px;
      font-weight: 350;
      display: flex;
      justify-content: space-between;
      align-items: center;
      position: relative;
    }

    .close_icon {
      position: absolute;
      z-index: 10;
      top: -30px;
      right: -30px;
      height: 90px;
      width: 80px;
      &:hover {
        cursor: pointer;
        outline: none;
      }
    }

    @media (min-width: breakpoints(medium)) {
      padding: 2rem;
    }
  }

  @media (min-width: breakpoints(medium)) {
    max-height: 100vh;
  }
}

.required_text {
  margin-top: 1rem;

  font-size: 14px;
  font-style: italic;
  font-weight: 325;
  line-height: 22px;

  color: $gray;
}

.grid {
  padding: 0;
  width: 100%;

  ::placeholder,
  input,
  textarea {
    font-size: 14px;
  }

  fieldset {
    width: 100%;
    padding: 0;
    margin: 0;

    label {
      color: #53565a;
      line-height: 24px;
      font-weight: 350;
      letter-spacing: 0.0625rem;
      font-size: 1rem;
    }
  }
}

.description {
  color: #53565a;
  font-size: 0.875rem;
  line-height: 25px;
  letter-spacing: 0.930414px;
  font-weight: 325;

  a {
    color: #cc0a2f;
    text-decoration: none;
    font-weight: 325;
    font-size: 0.875rem;
    width: fit-content;
    text-decoration: underline;
    &:hover {
      cursor: pointer;
    }
  }
}

.button_wrapper {
  button {
    padding: 1rem;
  }
}
